export default{
    title:"",
    create:"",
    edit:"",
    generate:"",
    no_more_combinations:"",
    add_variations:"",
    prices:"",
    use_inventory:"",
    remove_variations:""
}