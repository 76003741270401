export default{
    title:"Companies",
    create:"Create company",
    edit:"Edit company",
    disabled:"Disabled",
    enabled:"Enabled",
    statuses: {
        pending: "Pending",
        approved: 'Approved',
        rejected: 'Rejected',
        cancelled: 'Cancelled'
    },
    changed_by: "Changed por",
    approved_by: "Approved por",
    rejected_by: "Rejected por",
    cancelled_by: "Cancelled por",
    details: "Details of Company",
    lawsuits: "Last lawsuits",
    lawsuits_title: "Company data",
    isCompany: "Belongs to a company",
    isNoCompany: "Do you belong to a company?",
    statusLawsuit: "Status of lawsuit: "
}