export default{
    title:"Ofertas",
    create:"Crear oferta",
    edit:"Editar oferta",
    draw:"Ofertas deslizantes",
    title_draw:"Título de la oferta",
    accept:"Mostrar oferta deslizante",
    carruser:"Mostrar carrusel",
    title_config:"Gestión de imágenes del banner",
    description:"Ventana lateral que se abre de derecha a izquierda en la sección de la tienda.",
    discount:"Descuento",
    title_phone:"Imágenes para banner móvil",
    bannerLanding:"Imágenes del banner para el home superior",
    bannerLandingPhone:"Imágenes del banner para el home superior vista móvil",
    bannerLandingIntermediate:"Imágenes del banner para el home intermedio",
    bannerLandingIntermediatePhone:"Imágenes del banner para el home intermedio vista móvil"
}