export default{
    config_tittle: "Configuración del correo para envío",
    username: "Usuario",
    message_config: "Configuración del correo manual propio de la tienda",
    password: "Contraseña",
    encryption: "Encriptación",
    port: "Puerto",
    mailer: "Mailer",
    host: "Host",
    ej_username: "ej:",
    ej_encryption: "ej:tls",
    ej_port: "ej:2266",
    ej_mailer: "ej:smtp",
    ej_host: "ej:smtp.zoho.com"
}