export default{
    title: "Configuraciones",
    generals: "Generales",
    customization: "Personalización",
    points_of_sale: "Locales de recogida",
    notifications: "Notificaciones",
    misc: "Misceláneas",
    information: "Información",
    pay_forms: "Formas de pago",
    return_policy: "Política de devolución",
    warranty_policy: "Política de garantía",
    shipment_policy: "Política de entrega",
    frequent_asks: "Preguntas frecuentes",
    terms_conditions: "Términos y condiciones",
    about_us: "¿Quiénes somos?",
    shipment: "Envío",
    related_products: "Productos relacionados",
    shopping_cards: "Página de tarjetas de compra",
    from: "De",
    to: "Hasta",
    form: "Formulario",
    coins: "Monedas",
    messages_welcome:"Bienvenidos a la tienda Risol",
    inventary_products:"Inventario de productos",
    delivery:"Entrega",
    pay_form:"Formas de pago",
    account_email:"Cuentas de Correo"
}