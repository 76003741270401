export default{
    name: "Name",
    last_name: "Lastname",
    phone: "Phone",
    address: "Address",
    title: "Shipping beneficiary",
    own: "I am the beneficiary",
    new: "New beneficiary",
    edit: "Edit beneficiary",
    buy: "Shipping details",
    num_house: "House number",
    num_apartment: "Apartment number",
    date: "Beneficiary details",
    email: "Email",
    add_card: "Assign Card",
    pick_up: "Warehouse collection data",
    pick_up_address: "Pickup address",
    beneficiary_buy:"Purchase beneficiary",
    own_buy:"Is the buyer",
    check:"Add beneficiary",
    ci: "Personal identification",
    second_check: "Add second beneficiary",
    shipping_address: "Shipping address",
    no_address: "You have not added a shipping address yet",
    select_province_municipality: "Select a province and a municipality",
    select_province: "Select a province",
    select_municipality: "Select a municipality",
    verify_map_selection: "You have only entered the main street. Are you sure of the location on the map?",
    add_second_beneficiary:"Add alternative beneficiary?"
}