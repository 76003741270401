export default{
    config_tittle: "Email configuration",
    username: "User",
    message_config: "Manual email configuration specific to the store",
    password: "Password",
    encryption: "Encryption",
    port: "Port",
    mailer: "Mailer",
    host: "Host",
    ej_username: "ej:",
    ej_encryption: "ej:tls",
    ej_port: "ej:2266",
    ej_mailer: "ej:smtp",
    ej_host: "ej:smtp.zoho.com"
}