export default{
    title:"Carrito de compras",
    show:"Ver carrito",
    checkout:"Finalizar",
    empty:"Tu carrito está vacío",
    shipment_data:"Datos de entrega",
    payment_data:"Datos de pago",
    buyer_data:"Datos del comprador",
    add_beneficiary:"Nuevo beneficiario",
    delete_beneficiary_question:"¿Desea eliminar el beneficiario?",
    no_beneficiaries:"No tiene registrado ningún beneficiario",
    beneficiary:"Beneficiario de compra",
    second_beneficiary:"Segundo beneficiario de compra",
    alternative_beneficiary:"Beneficiario alternativo",
    shipment_method:"Método de entrega",
    home_shipping_address_label:"Dirección a enviar",
    local_pickup_address_label:"Dirección para recogida",
    beneficiary_alternative:"Beneficiario alternativo para compra",
    messages_payer:"Propietario de la cuenta desde donde se realiza el pago.",
    messages_beneficiary:"Persona que recibirá y será propietario del producto.",
    messages_beneficiary_alternative:"Persona que puede recibir el producto en ausencia del beneficiario principal.",
    no_beneficiary_selected:"No hay ningún beneficiario seleccionado",
    no_alternative_beneficiary_selected:"No hay ningún beneficiario alternativo seleccionado",
    go_to_payment:"Método de pago",
    go_to_shipment:"Método de entrega"
}