export default{
    title:"Shopping cart",
    show:"Show cart",
    checkout:"Checkout",
    empty:"Your shopping cart is empty",
    shipment_data:"Delivery information",
    payment_data:"Payment datails",
    buyer_data:"Buyer details",
    add_beneficiary:"New beneficiary",
    delete_beneficiary_question:"Do you want to delete the beneficiary? ",
    no_beneficiaries:"No registered beneficiary",
    beneficiary:"Purchase beneficiary",
    second_beneficiary:"Second beneficiary of purchase",
    alternative_beneficiary:"Alternative beneficiary",
    shipment_method:"Delivery method",
    home_shipping_address_label:"Address to send",
    local_pickup_address_label:"Pickup address",
    beneficiary_alternative:"Alternative beneficiary for purchase",
    messages_payer:"Owner of the account from which the payment is made.",
    messages_beneficiary:"Person who will receive and own the product.",
    messages_beneficiary_alternative:"Person who can receive the product in the absence of the main beneficiary.",
    no_beneficiary_selected:"There is no beneficiary selected",
    no_alternative_beneficiary_selected:"No alternate beneficiary selected",
    go_to_payment:"Payment method",
    go_to_shipment:"Delivery method"
}