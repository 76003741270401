export default{
    title:"Productos",
    list:"Listado de productos",
    create:"Crear producto",
    edit:"Actualizar producto",
    related:"Productos relacionados",
    general:"General",
    error_quantity:"Cantidad no permitida",
    type:{
        title:"Tipo de producto",
        options:{
            simple:"Simple",
            variable:"Variable"
        }
    },
    in_offer: "En Oferta",
    show_product: "Detalles del producto",
    soon:"Próximamente"

}