export default{
    title:"Variaciones",
    create:"Crear variación",
    edit:"Editar variación",
    generate:"Generar todas las combinaciones",
    no_more_combinations:"No hay mas combinaciones.",
    add_variations:"Agregar variaciones",
    prices:"Precios",
    use_inventory:"Usar inventario",
    remove_variations:"¿Desea eliminar esta variación?"
}