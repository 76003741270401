export default {  
    title: "Orders",  
    my: "My Orders",  
    create: "Create Order",  
    edit: "Edit Order",  
    shipping_method: "Shipping Method",  
    home_shipping: "Warehouse Pickup",  
    local_pickup: "Home Delivery",  
    summary: "Summary",  
    quantity_not_allowed: "Quantity Not Allowed",  
    quantity_not_allowed_plural: "Quantities Not Allowed",  
    quantity_not_allowed_singular: "Quantity Not Allowed",  
    create_and_pay: "Create and Pay",  
    just_create_note: "Please wait a few seconds",  
    details: "Details",  
    distribute: "Distribution",
    single:"Order"
}