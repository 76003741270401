export default{
    title: "Banners",
    create: "Create banner",
    edit: "Edit",
    delete: "Delete",
    section: "Banner section URL",
    title_text: "Banner title",
    title_button: "Button text",
    url: "Redirect URL",
    active_web: "Visible in web mode",
    active_movile: "Visible in mobile mode",
    button_color: "Button color",
    button_text_color: "Button text color",
    button_position: "Button position",
    TOP_CENTER: "Top center",
    TOP_LEFT: "Top left",
    TOP_RIGHT: "Top right",
    CENTER_RIGHT: "Center right",
    CENTER_LEFT: "Center left",
    CENTER: "Center",
    BOTTOM_LEFT: "Bottom left",
    BOTTOM_CENTER: "Bottom center",
    BOTTOM_RIGHT: "Bottom right",
    top_center: "Top center",
    top_left: "Top left",
    top_right: "Top right",
    center_right: "Center right",
    center_left: "Center left",
    center: "Center",
    bottom_left: "Bottom left",
    bottom_center: "Bottom center",
    bottom_right: "Bottom right",
    banner_data: "Banner data",
    buttom_data: "Button data",
    button_border_color: "Border color",
    empty_for_transparent: "Leave blank for transparent border",
    button_border_width: "Border width",
    in_pixels: "In pixels",
}