export default{
    title:"",
    sales:"",
    sales_over_time:"",
    mostSold:"",
    items:"",
    use_life_time:"",
    visits:"",
    new_users:"",
    pages_most_visited:"",
    products_most_visited:"",
    products:""
}