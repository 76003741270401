export default{
    title:"Pedidos",
    my:"Mis pedidos",
    create:"Crear pedido",
    edit:"Editar pedido",
    shipping_method:"Método de envío",
    home_shipping:"Recogida en almacén",
    local_pickup:"Envío a domicilio",
    summary:"Resumen",
    quantity_not_allowed:"Cantidad no permitida",
    quantity_not_allowed_plural: "Cantidades no permitidas",
    quantity_not_allowed_singular:"Cantido no permitida",
    create_and_pay:"Pagar",
    just_create_note:"Espere unos segundos por favor",
    details:"Detalles",
    distribute:"Distribución",
    single:"Pedido"

}