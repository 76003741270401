export default{
    title:"Offers",
    create:"Create offert",
    edit:"Edit offert",
    draw:"Sliding offers",
    title_draw:"Offer title",
    accept:"Show sliding bid",
    carruser:"Show carousel",
    title_config:"Banner Image Management",
    description:"Side window that opens from right to left in the store section.",
    discount:"Discount",
    title_phone:"Images for mobile banner",
    bannerLanding:"Images of the banner for the top home",
    bannerLandingPhone:"Banner images for home top mobile view",
    bannerLandingIntermediate:"Images of the banner for the intermediate home",
    bannerLandingIntermediatePhone:"Banner Images for Intermediate Home Mobile View"
}