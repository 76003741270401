export default{
    title:"Analíticas",
    sales:"Ventas",
    sales_over_time:"Comportamiento de las ventas en el tiempo",
    mostSold:"Productos más vendidos",
    items:"Elementos",
    use_life_time:"Datos de por vida",
    visits:"Accesos",
    new_users:"Nuevos usuarios",
    pages_most_visited:"Páginas más visitadas",
    products_most_visited:"Productos más visitados",
    products:"Productos"
}