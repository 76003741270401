export default {
        title: "Actions",
        details: "Details",
        edit: "Edit",
        delete: "Delete",
        enabled: "Enabled",
        disabled: "Disabled",
        cancel: "Cancel",
        ok: "Ok",
        add: "Add",
        search: "Search",
        update: "Update",
        login: "Login",
        login_with_google: "Login with Google",
        register: "Register",
        logout: "Logout",
        confirm: "Confirm",
        send_email: "Send email",
        reset_password: "Reset password",
        resend_verification: "Resend verification link",
        delete_account: "Delete account",
        save: "Save",
        resend_verification_email: "Click here to resend the verification email.",
        show: "View details",
        show_variations: "Show variations",
        show_options: "Show options",
        checkout: "Checkout",
        complete: "Complete",
        continue: "Continue",
        finish: "Finish",
        back: "Back",
        generate: "Generate",
        close: "Close",
        reset: "Reset",
        pay_with: "Pay with {name}",
        create_invoice: "Create invoice",
        export: "Export",
        pay: "Pay",
        request_info: "Request information",
        send: "Send",
        change_status: "Change status",
        change: "Change",
        distribute_order: "Dispatch order",
        read_more: "Read more...",
        select_pickup_address: "Pickup address",
        buy: "Buy",
        show_orderable: "Show orderable",
        show_reservation: "Show reservation",
        see_more_details: "See details",
        use_beneficiary_data: "Use beneficiary data",
        dispatch_order: "Dispatch order",
        pay_invoice: "Mark invoice as paid",
        see_more: "See more...",
        use_variation: "Use variation",
        new_schedule: "Create schedule set",
        print: "Print",
        approve: "Approve",
        decline: "Decline",
        share: "Share",
        select_all: "Select all",
        deselect_all: "Deselect all",
        search_product: "Search products",
        shop_by_category: "Shop by category",
        see_products: "See product details",
        insert_address: "Insert address",
        edit_address: "Edit address",
        newLawsuit: "New lawsuit",
        change_contacted: "Change contacted"
    };